import React from "react";

import { Grid, Typography } from "@mui/material";

import FiltersReport from "../components/FiltersReport";
import ReportBuilder, {
    REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
    fields_OverviewTable,
    REPORT_TABLE_METRICS_SUMMARY,
    REPORT_TABLE_OVERVIEW_YEAR_MONTH,
} from "../components/shared/ReportBuilder/ReportBuilder";
import {
    COMPARISON_SAME_TIME_LAST_YEAR,
    DATATYPE_CHECKIN,
    DISPLAY_ELEMENT_HELPER,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    PRESET_ANYTIME,
    PRESET_CHOOSE_MONTH,
    PRESET_NEXT_12_MONTHS,
    PRESET_NEXT_30_DAYS,
    PRESET_NEXT_3_MONTHS,
    PRESET_NEXT_MONTH,
    PRESET_THIS_MONTH,
} from "../helpers/filters";

const REPORTS = [
    {
        type: REPORT_TABLE_METRICS_SUMMARY,
        groupBy: [],
        dataType: DATATYPE_CHECKIN,
        fieldsOrder: [
            "revenueRoom",
            "bookingsRooms",
            "roomNightsTotal",
            "bookingsCancelled",
            "ADRTotal",
            "ABWTotal",
            "ALoSTotal",
            "revenueExtras",
        ],
        hideFields: [
            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                (e) =>
                    ![
                        "revenueRoom",
                        "roomNightsTotal",
                        "ADRTotal",
                        "ABWTotal",
                        "ALoSTotal",
                        "bookingsRooms",
                        "revenueExtras",
                        "bookingsCancelled",
                    ].includes(e)
            ),
        ],
        PDF: "Metrics",
        // props: { hideEvents: true },
    },
    {
        type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
        // dataType: DATATYPE_CHECKIN,
        title: "Rate Plan - Trend chart - 1",
        groupBy: [
            // GROUP_OFFERS,
            GROUP_DATE_YEAR,
            GROUP_DATE_MONTH,
            GROUP_DATE_DAY,
        ],
        filters: { Types: ["Special Offer"] },
        props: {
            title: "Arrivals chart",
            metricsTitle: "‎ ",
            metrics: {
                bookingsTotal: {
                    ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                    short: "Arrivals",
                },
                roomNightsTotal: { ...DISPLAY_ELEMENT_HELPER.roomNightsTotal },
                revenueRoom: { ...DISPLAY_ELEMENT_HELPER.revenueRoom },
                ADRTotal: { ...DISPLAY_ELEMENT_HELPER.ADRTotal },
            },
            showLastYear: true,
        },
    },
    {
        type: REPORT_TABLE_OVERVIEW_YEAR_MONTH,
        props: {
            title: "Arrivals by Month",
        },
        dataType: DATATYPE_CHECKIN,
        groupBy: [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
        ...fields_OverviewTable,
        sortable: [],
        filters: { Types: ["Special Offer"] },
        fieldsOrder: [
            "bookingsRooms",
            // "bookingsTotal",
            "roomNightsTotal",
            "revenueRoom",
            "ADRTotal",
            // "ALoSTotal",
            // "ABWTotal",
            // "BWTotal",
            // "bookingsCancelled",
        ],
        hideFields: [
            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                (e) =>
                    ![
                        "bookingsRooms",
                        // "bookingsTotal",
                        "roomNightsTotal",
                        "revenueRoom",
                        "ADRTotal",
                        // "ALoSTotal",
                        // "ABWTotal",
                        // "BWTotal",
                        // "bookingsCancelled",
                    ].includes(e)
            ),
        ],
    },
];

const ArrivalsScreen = () => {
    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Arrival report</Typography>
                    <Typography variant="subtitle1" color="initial">
                        This report shows the arrivals over a selected period of
                        time. The comparison is from todays date last year.
                    </Typography>
                </Grid>
            </Grid>
            <FiltersReport
                PDF
                defaultValues={{
                    dataType: DATATYPE_CHECKIN,
                    presetDate: PRESET_NEXT_12_MONTHS,
                    presetArrivalDate: PRESET_ANYTIME,
                    // filters: { Types: ["Special Offer"] },
                    filters: {
                        Cancellations: "included",
                        Comparison: COMPARISON_SAME_TIME_LAST_YEAR,
                        // Types: ["Special Offer"], //TODO: Check this
                    },
                }}
                BookingPresetOptions={[
                    PRESET_THIS_MONTH,
                    PRESET_NEXT_MONTH,
                    PRESET_NEXT_30_DAYS,
                    PRESET_NEXT_3_MONTHS,
                    PRESET_NEXT_12_MONTHS,
                    PRESET_CHOOSE_MONTH,
                ]}
                rename={{
                    TIME_PERIOD: "Arrival period",
                    TIME_RANGE: "Custom Arrival Dates",
                }}
                hide={[
                    "DATATYPE",
                    "FILTERS_VOUCHERS",
                    "COMPARISON",
                    "CANCELLATIONS",
                    "ARRIVAL_PERIOD",
                    "TIME_ARRIVAL_RANGE",
                    "RESDATE_PERIOD",
                    "TIME_BOOKING_RANGE",
                    "FILTERS_TYPES",
                ]}
                setProcessed={() => {}}
            />
            <ReportBuilder reports={REPORTS} />
        </>
    );
};

export default ArrivalsScreen;
