import React, { useState } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";

import SmallPercentage from "../SmallPercentage";

import { fns } from "../../helpers/common";
import {
    COMPARISON_CUSTOM,
    COMPARISON_FILTER,
    COMPARISON_PREVIOUS_MONTH,
    COMPARISON_PREVIOUS_WEEK,
    COMPARISON_PREVIOUS_YEAR,
    COMPARISON_SAME_TIME_LAST_YEAR,
    DEFAULT_COMPARISON,
    DISPLAY_ELEMENT_HELPER,
    getMetricName,
} from "../../helpers/filters";
import Colours from "../../helpers/colours";
import {
    formatDate,
    subtractOneMonth,
    subtractOneWeek,
    subtractOneYear,
} from "../../helpers/dates";
import { useSelector } from "react-redux";

const TooltipComparison = ({
    metricName,
    comparisonType,
    comparisonCustom,
    calculateComparisonDate = true,
    fromDay,
    toDay,
    value,
    valueOld,
    children,
    setSelected = () => {},
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    let comparison_label =
        COMPARISON_FILTER[comparisonType ?? DEFAULT_COMPARISON].label;

    const setLabel = (date) => {
        if (calculateComparisonDate) {
            switch (comparisonType) {
                case COMPARISON_PREVIOUS_YEAR:
                    return subtractOneYear(date);
                case COMPARISON_PREVIOUS_MONTH:
                    return subtractOneMonth(date);
                case COMPARISON_PREVIOUS_WEEK:
                    return subtractOneWeek(date);
                default:
                    return subtractOneYear(date);
            }
        } else return formatDate(date);
    };

    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: "white",
                        color: "black",
                        borderTop: `2px solid ${Colours.primary}`,
                        borderRadius: "4px",
                        boxShadow: "0px 0px 12px rgba(0,0,0,0.1)",
                    },
                },
                arrow: {
                    sx: {
                        color: "white",
                        fontSize: "1.75em",
                        "&:before": {
                            border: `2px solid ${Colours.primary}`,
                            borderBottomColor: "transparent",
                            borderRightColor: "transparent",
                        },
                    },
                },
            }}
            arrow
            title={
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                    m={2}
                    my={1}
                >
                    <Typography fontSize="1.25em" fontWeight={"bold"}>
                        {comparison_label}
                    </Typography>
                    <Typography fontSize="1em" fontWeight={"light"}>
                        {comparisonType !== COMPARISON_CUSTOM // TODO: This is not correct, we have to take results last year from and to
                            ? `${setLabel(fromDay)} - ${setLabel(toDay)}`
                            : `${formatDate(
                                  new Date(comparisonCustom.from ?? new Date())
                              )} - ${formatDate(
                                  new Date(comparisonCustom.to ?? new Date())
                              )}`}
                    </Typography>
                    {!isNaN(valueOld) ? (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={4}
                        >
                            <Typography fontWeight={"bold"}>
                                {fns(hotelID, valueOld, {})}
                            </Typography>
                            <SmallPercentage
                                usePercentage={false}
                                brackets={false}
                                tooltip={false}
                                bubble={true}
                                bubbleRound={true}
                                actual={value - valueOld}
                                old={1}
                                text={fns(
                                    hotelID,
                                    parseFloat((value - valueOld).toFixed(2)),
                                    {}
                                )}
                                showFormula={false}
                                isInverted={
                                    (DISPLAY_ELEMENT_HELPER[metricName] ?? {})
                                        .upside ?? false
                                }
                                styles={{ fontSize: "12px" }}
                            />
                        </Stack>
                    ) : (
                        <Typography fontSize={"1.25em"} fontStyle={"italic"}>
                            No previous value
                        </Typography>
                    )}
                </Stack>
            }
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
        >
            {children}
        </Tooltip>
    );
};

export default TooltipComparison;
