import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import EChartsReact from "echarts-for-react";
import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import SmallPercentage from "../../SmallPercentage";
import TooltipComparison from "../TooltipComparison";

import Icons from "../../../helpers/icons";
import Colours from "../../../helpers/colours";
import {
    formatDate,
    getDateFromWeekNumberSince2000,
    getWeekNumberSince2000,
} from "../../../helpers/dates";
import {
    DISPLAY_ELEMENT_HELPER,
    getMetricName,
    GROUP_DATE_MONTH,
    GROUP_DATE_WEEK,
    GROUP_DATE_YEAR,
} from "../../../helpers/filters";
import { fns } from "../../../helpers/common";
import NoDataRow from "../../NoDataRow";

const WLeft = "145px";
const WRight = "80px";
const WPaddingLeftGraph = "145px";
const WPaddingRightGraph = "80px";

const SHOW_LIMIT = 5;

const COLORS = {
    BOOKINGS_CURRENT: "#888888",
    BOOKINGS_STLY: "#a7a7a7",
    ROOM_NIGHTS_CURRENT: "#2d2d2d",
    ROOM_NIGHTS_STLY: "#555555",
    REVENUE_CURRENT: "#4f4f4f",
    REVENUE_STLY: "#b0b0b0",
    TABLE_ROW_BACKGROUND: Colours.tableRowBackground,
    AXIS_LINE: "#404040",
};

// TODO: Allow metrics to show to be chosen in a dict
const ReportChartInTable = ({
    js,
    data,
    place,
    title,
    showAxisLabels = false,
    bookingsText = "Bookings",
}) => {
    const theme = useTheme();
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [legendGap, setLegendGap] = useState(10);
    let isSm = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        if (isSm) {
            setLegendGap(10);
        } else {
            setLegendGap(50);
        }
    }, [isSm, theme]);

    const [currentDataIndex, setCurrentDataIndex] = useState(0);

    const getNextDataSlice = () => {
        setCurrentDataIndex((prevIndex) =>
            Math.min(prevIndex + 1, Math.max(0, indexes.length - SHOW_LIMIT))
        );
    };

    const getPrevDataSlice = () => {
        setCurrentDataIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const getData = (groupKey, key, currentPrev) =>
        data[groupKey][currentPrev ? "result" : "resultOneYearBefore"][key] ??
        0;

    const getFromFromData = (itemKey) =>
        data[itemKey]["resultOneYearBefore"]["fromDate"];

    const getToFromData = (itemKey, values) =>
        data[itemKey]["resultOneYearBefore"]["toDate"];

    const getFirstWeekDay = (weekNumberFrom2000) => {
        return formatDate(
            getDateFromWeekNumberSince2000(weekNumberFrom2000, 6)
        );
    };

    const modeWeek = () =>
        js.groupBy[0] === GROUP_DATE_WEEK && js.groupBy.length === 1;

    const modeMonth = () =>
        js.groupBy.includes(GROUP_DATE_YEAR) &&
        js.groupBy.includes(GROUP_DATE_MONTH) &&
        js.groupBy.length === 2;

    const [indexes, setIndexes] = useState([]);
    useEffect(() => {
        let fromIndex = null;
        if (modeWeek()) {
            fromIndex = getWeekNumberSince2000(new Date(js.fromDay));
            // This is to avoid last year entries
            setIndexes(
                Object.keys(data)
                    .filter((v) => v >= fromIndex)
                    .sort()
            );
        } else if (modeMonth()) {
            const fixedIndex = (index) => {
                const parts = index.split("/");
                const year = parts[0];
                const month = parts[1].padStart(2, "0"); // Month always has to have 2 digits for comparison
                return `${year}/${month}`;
            };

            fromIndex = `${new Date(js.fromDay).getUTCFullYear()}/${new Date(
                js.fromDay
            ).getUTCMonth()}`;

            setIndexes(
                Object.keys(data)
                    .filter((v) => fixedIndex(v) >= fixedIndex(fromIndex))
                    .sort()
            );
        }
    }, [data]);

    const [hasData, setHasData] = useState(true);
    useEffect(() => {
        setHasData(
            indexes.some((key) => {
                const currentResult = data[key]?.result;
                if (!currentResult || Object.keys(currentResult).length === 0) {
                    return false;
                }
                return true;
            })
        );
    }, [indexes]);

    return (
        <>
            {title && (
                <Typography fontWeight={"bold"} sx={{ pt: 2 }}>
                    {title}
                </Typography>
            )}
            <Table
                style={{
                    width: "100%",
                    backgroundColor: COLORS.TABLE_ROW_BACKGROUND,
                    borderCollapse: "separate",
                    borderSpacing: 0,
                }}
                sx={{
                    borderRadius: "1em 1em 0 0",
                    overflow: "hidden",
                    "& th, & td": {
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                }}
            >
                <TableHead sx={{ borderTopLeftRadius: 8 }}>
                    <TableRow
                        sx={{
                            borderTopLeftRadius: 8,
                            display: "flex",
                            width: "100%",
                        }}
                    >
                        <TableCell
                            sx={{
                                width: WLeft,
                                borderTopLeftRadius: 8,
                                flex: "0 0 auto",
                            }}
                            onClick={getPrevDataSlice}
                        >
                            <IconButton disabled={currentDataIndex === 0}>
                                <Icons.ArrowLeft />
                            </IconButton>
                        </TableCell>
                        {!hasData ? (
                            <TableCell
                                sx={{
                                    flex: "1",
                                    padding: "0px",
                                    textAlign: "center",
                                    alignContent: "center",
                                }}
                            >
                                <NoDataRow
                                    text="No data for these filters"
                                    justText
                                />
                            </TableCell>
                        ) : (
                            indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) => (
                                    <TableCell
                                        key={itemKey}
                                        sx={{
                                            flex: "1",
                                            padding: "0px",
                                            textAlign: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        {modeWeek() ? "Week ending" : "Month"}
                                        <br />
                                        <Typography fontWeight={"bold"}>
                                            {modeWeek()
                                                ? getFirstWeekDay(itemKey)
                                                : formatDate(
                                                      new Date(
                                                          itemKey.slice(0, 4),
                                                          itemKey.slice(5, 7) -
                                                              1,
                                                          1
                                                      ).toISOString(),
                                                      false
                                                  )}
                                        </Typography>
                                    </TableCell>
                                ))
                        )}
                        <TableCell
                            sx={{
                                width: WRight,
                                maxWidth: WRight,
                                textAlign: "right",
                                flex: "0 0 auto",
                            }}
                            onClick={getNextDataSlice}
                        >
                            <IconButton
                                disabled={
                                    currentDataIndex >=
                                    Math.max(0, indexes.length - SHOW_LIMIT)
                                }
                            >
                                <Icons.ArrowRight />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            {/* <br /> */}
            {hasData && (
                // <TableCell
                //     sx={{
                //         flex: "1",
                //         padding: "0px",
                //         textAlign: "center",
                //         alignContent: "center",
                //     }}
                // >
                //     <NoDataRow text="No data for these filters" justText />
                // </TableCell>
                <EChartsReact
                    key={legendGap}
                    style={{
                        marginTop: "15px",
                        height: 300,
                        width: "100%",
                        // paddingLeft: WPaddingLeftGraph,
                        // paddingRight: WPaddingRightGraph,
                    }}
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow",
                            },
                        },
                        legend: {
                            orient: "horizontal",
                            itemGap: legendGap,
                            width: "100%",
                        },
                        grid: {
                            left: WPaddingLeftGraph,
                            right: WPaddingRightGraph,
                            bottom: "8%",
                            // containLabel: true,
                        },
                        xAxis: {
                            type: "category",
                            data: indexes
                                .slice(
                                    currentDataIndex,
                                    currentDataIndex + SHOW_LIMIT
                                )
                                .map((itemKey, index) =>
                                    modeWeek()
                                        ? getFirstWeekDay(itemKey)
                                        : itemKey
                                ),
                        },
                        yAxis: [
                            {
                                type: "value",
                                position: "left",
                                splitNumber: 3,
                                alignTicks: true,
                                axisLabel: {
                                    formatter: "{value}",
                                    show: showAxisLabels,
                                },
                            },
                            {
                                type: "value",
                                position: "right",
                                splitNumber: 3,
                                alignTicks: true,

                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: COLORS.AXIS_LINE,
                                    },
                                },
                                axisLabel: {
                                    formatter: "{value}",
                                    show: showAxisLabels,
                                },
                            },
                        ],
                        series: [
                            {
                                name: `${bookingsText} (Current)`,
                                type: "bar",
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(itemKey, "bookingsRooms", true)
                                    ),
                                color: COLORS.BOOKINGS_CURRENT,
                            },
                            {
                                name: `${bookingsText} STLY`,
                                type: "bar",
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(itemKey, "bookingsRooms", false)
                                    ),
                                color: COLORS.BOOKINGS_STLY,
                            },
                            {
                                name: "Room Nights (Current)",
                                type: "bar",
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(
                                            itemKey,
                                            "roomNightsTotal",
                                            true
                                        )
                                    ),
                                color: COLORS.ROOM_NIGHTS_CURRENT,
                            },
                            {
                                name: "Room Nights STLY",
                                type: "bar",
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(
                                            itemKey,
                                            "roomNightsTotal",
                                            false
                                        )
                                    ),
                                color: COLORS.ROOM_NIGHTS_STLY,
                            },
                            {
                                name: "Revenue (Current)",
                                type: "line",
                                smooth: true,
                                yAxisIndex: 1,
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(itemKey, "revenueTotal", true)
                                    ),
                                color: COLORS.REVENUE_CURRENT,
                            },
                            {
                                name: "Revenue STLY",
                                type: "line",
                                smooth: true,
                                yAxisIndex: 1,
                                data: indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey, index) =>
                                        getData(itemKey, "revenueTotal", false)
                                    ),
                                color: COLORS.REVENUE_STLY,
                            },
                        ],
                    }}
                />
            )}
            {hasData && (
                <Table
                    sx={{
                        "& th, & td": {
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                        },
                    }}
                >
                    <TableBody>
                        {[
                            {
                                k: "bookingsRooms",
                                text: `Room ${bookingsText}`,
                            },
                            { k: "roomNightsTotal", text: "Room Nights" },
                            {
                                k: "revenueTotal",
                                text: `${fns(
                                    hotelID,
                                    getMetricName("revenueTotal", place) ??
                                        "revenueTotal",
                                    (
                                        DISPLAY_ELEMENT_HELPER[
                                            "revenueTotal"
                                        ] ?? {}
                                    ).headerFormat
                                )}`,
                            },
                            {
                                k: "ADRTotal",
                                text: `${fns(
                                    hotelID,
                                    getMetricName("ADRTotal", place) ??
                                        "ADRTotal",
                                    (DISPLAY_ELEMENT_HELPER["ADRTotal"] ?? {})
                                        .headerFormat
                                )}`,
                            },
                        ].map(({ k: metric, text }) => (
                            <TableRow
                                key={metric}
                                sx={{ display: "flex", width: "100%" }}
                            >
                                <TableCell
                                    sx={{
                                        width: WLeft,
                                        maxWidth: WLeft,
                                        backgroundColor:
                                            COLORS.TABLE_ROW_BACKGROUND,
                                        flex: "0 0 auto",
                                        alignContent: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize="0.9em"
                                    >
                                        {text}
                                    </Typography>
                                </TableCell>
                                {indexes
                                    .slice(
                                        currentDataIndex,
                                        currentDataIndex + SHOW_LIMIT
                                    )
                                    .map((itemKey) => (
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                                alignContent: "center",
                                                flex: "1",
                                            }}
                                        >
                                            <TooltipComparison
                                                // placeForMetricName={PLACE}
                                                metricName={metric}
                                                comparisonType={
                                                    js.filters.Comparison
                                                }
                                                comparisonCustom={
                                                    js.filters.ComparisonCustom
                                                }
                                                calculateComparisonDate={false}
                                                fromDay={getFromFromData(
                                                    itemKey
                                                )}
                                                toDay={getToFromData(itemKey)}
                                                value={getData(
                                                    itemKey,
                                                    metric,
                                                    true
                                                )}
                                                valueOld={getData(
                                                    itemKey,
                                                    metric,
                                                    false
                                                )}
                                            >
                                                <Stack
                                                    direction="column"
                                                    sx={{
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {getData(
                                                        itemKey,
                                                        metric,
                                                        true
                                                    )}
                                                    <SmallPercentage
                                                        brackets={false}
                                                        tooltip={false}
                                                        bubble={true}
                                                        bubbleRound={true}
                                                        actual={getData(
                                                            itemKey,
                                                            metric,
                                                            true
                                                        )}
                                                        actualName="Current period"
                                                        oldName={"Last period"}
                                                        old={getData(
                                                            itemKey,
                                                            metric,
                                                            false
                                                        )}
                                                        showFormula={false}
                                                    />
                                                </Stack>
                                            </TooltipComparison>
                                        </TableCell>
                                    ))}

                                <TableCell
                                    sx={{
                                        width: WRight,
                                        flex: "0 0 auto",
                                        border: "none !important",
                                    }}
                                ></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </>
    );
};

export default ReportChartInTable;
