import React, { useEffect, useState } from "react";

import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";

import {
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
} from "../helpers/filters";

const ToggleButtonTitled = ({
    TITLE = "Show performance by",
    values = {
        DATATYPE_RESDATE: "Booked on date",
        DATATYPE_CHECKIN: "Checked In Date",
        DATATYPE_CHECKOUT: "Checked Out Date",
    },
    valueSet = DATATYPE_RESDATE,
    onChange = () => {},
    ITEM_HEIGHT = "50px",
    size = "large",
}) => {
    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        setUpdateKey(updateKey + 1);
    }, []);

    return (
        <Grid item>
            <Typography>{TITLE}</Typography>
            <ToggleButtonGroup
                size={size}
                value={valueSet}
                exclusive
                onChange={(e, v) => {
                    if (v) {
                        onChange(e, v);
                    }
                }}
                key={updateKey}
                sx={{
                    height: ITEM_HEIGHT,
                    border: 1,
                    borderColor: "divider",
                    "& .MuiToggleButtonGroup-grouped": {
                        margin: "4px",
                        border: "none",
                        transition: "background-color 300ms",
                        borderRadius: "4px",
                        "&:not(:first-of-type)": {
                            borderRadius: "4px",
                        },
                        "&:not(:last-of-type)": {
                            borderRadius: "4px",
                        },
                        pt: 0,
                        pb: 0,
                        pr: 2,
                        pl: 2,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        boxShadow: updateKey
                            ? "0px 0px 0px transparent"
                            : "none",
                    },

                    "& .MuiToggleButton-root": {
                        color: "primary.contrastText",
                    },

                    "&.Mui-selected, &.Mui-selected:hover, .Mui-selected, .Mui-selected:hover":
                        {
                            color: "primary.contrastText",
                            backgroundColor: "primary.main",
                            "&:before": {
                                position: "absolute",
                                content: '""',
                            },
                        },
                }}
            >
                {Object.entries(values).map(([val, label]) => {
                    return (
                        <ToggleButton value={val} key={label}>
                            {label}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </Grid>
    );
};

export default ToggleButtonTitled;
