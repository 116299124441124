// export const URLAPI = "http://localhost:5000"; // Local
// export const URLAPI = "https://cuchulainnapi.staging.dev.arosuite.com/"      // Staging
export const URLAPI = "https://cuchulainnapi.dev.arosuite.com"; // Master

export const AROSUITE_MASTER = "https://arosuite.com";
export const AROSUITE_BETA = "https://arosuitebeta.arosuite.com";

export const DOTNET_URLAPI = "https://cuchulainndotnetapi.dev.arosuite.com";

export const AROLOGO = "/aro-analytics-logo.svg";
// export const AROLOGO = "/aro-plus-logo.svg";

export const RETRYAPI = 0.25 * 60000;

export const ARO_ADMIN = ["AroAdmin"];
export const ARO_ALL = ["AroAdmin", "AroGeneral"];
export const ARO_HOTELS = ["AroAdmin", "AroGeneral", "HotelGeneral"];
export const HOTELS_ONLY = ["HotelGeneral"];
export const HIDED = ["NoneRole"];
export const ILH_ROLE = ["HotelImperialLondonWrite"];
export const HOTEL_GROUPS = [...ILH_ROLE];

export const NAVBAR_ROLES = {
    InsightsAssistScreen: ARO_ALL,
    IndicatorsScreen: ARO_ADMIN,
    SwitchHotel: ARO_ALL,
    RedAlertsScreen: ARO_ALL,
    ReleaseScreen: ARO_ALL,
    TargetsScreen: ARO_ALL,

    SettingsScreen: ARO_ADMIN,
    ProfileScreen: ARO_ADMIN,

    IconsScreen: ARO_ADMIN,
    HotelIdentifierScreen: ARO_ALL,
    EmailListScreen: ARO_ALL,

    // ProfileScreen: HOTELS_ONLY,
};

export const DEFAULT_HOTEL = 88; //Park House

export const ILH_HOTEL_LIST = ["93", "94", "95", "96", "97", "107"];
export const BROCHNER_HOTEL_LIST = ["116", "117", "118", "119", "139"];
export const GBH_ID = 14;

export const HOTELS_INFO = {
    default: {
        img: "/imgs/GrandBudapest.png",
        name: "The Grand Budapest Hotel",
        id: "default", // TODO: investigate if we can place a 14 here
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    1: {
        img: "/imgs/Twelve.svg",
        name: "The Twelve",
        id: 1,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    2: {
        img: "/imgs/Ballynahinch.svg",
        name: "Ballynahinch Castle",
        id: 2,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    4: {
        img: "/imgs/Hayfield.svg",
        name: "Hayfield Manor",
        id: 4,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    5: {
        img: "/imgs/BallynahinchLodge.jpg",
        name: "Ballyliffin Lodge Hotel & Spa",
        id: 5,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },

    // ----------------------------------------

    8: {
        img: "/imgs/Redcastle.svg",
        name: "Redcastle Hotel",
        id: 8,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    9: {
        img: "/imgs/Sneem-Black.svg",
        name: "Sneem Hotel",
        id: 9,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    10: {
        img: "/imgs/knockranny.png",
        name: "Knockranny House Hotel",
        id: 10,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    12: {
        img: "/imgs/TitanicBelfast.svg",
        name: "Titanic Belfast",
        id: 12,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    13: {
        img: "/imgs/BenedictsofBelfast.svg",
        name: "Benedicts of Belfast",
        id: 13,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    14: {
        img: "/imgs/GrandBudapest.png",
        name: "The Grand Budapest Hotel",
        id: "default",
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    23: {
        img: "/imgs/GrandCentralBundoran.svg",
        name: "Grand Central Bundoran",
        id: 23,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    27: {
        img: "/imgs/Killyhevlin.svg",
        name: "Killyhevlin",
        id: 27,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },

    28: {
        img: "/imgs/Killyhevlin.svg",
        name: "Killyhevlin Lodges",
        id: 28,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },

    30: {
        img: "/imgs/KnockHouse.svg",
        name: "Knock House Hotel",
        id: 30,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    41: {
        img: "/imgs/TeachDeBroc.svg",
        name: "Teach De Brock Country House",
        id: 41,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },

    34: {
        img: "/imgs/MillsInn.svg",
        name: "Mills Inn",
        id: 34,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    45: {
        img: "/imgs/TheTalbotHotel.png",
        name: "Talbot Hotel",
        id: 45,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    53: {
        img: "/imgs/Dunraven.svg",
        name: "Dunraven Arms",
        id: 53,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    54: {
        img: "/imgs/FlannerysHotel.png",
        name: "Flannery's Hotel",
        id: 54,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    55: {
        img: "/imgs/Powerscourt.svg",
        name: "Powerscourt Hotel",
        id: 55,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
        hide: {
            Vouchers: 1,
        },
    },
    57: {
        img: "/imgs/TheLandmarkHotel.svg",
        name: "The Landmark Hotel",
        id: 57,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    58: {
        img: "/imgs/Rock.svg",
        name: "The Rock",
        id: 58,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    59: {
        img: "/imgs/TempleHouse.svg",
        name: "Temple House",
        id: 59,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    60: {
        img: "/imgs/WaterfordCastleResort.svg",
        name: "Waterford Castle Resort",
        id: 60,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    64: {
        img: "/imgs/TheTraddock.png",
        name: "The Traddock",
        id: 64,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    68: {
        img: "/imgs/TitanicLiverpool.svg",
        name: "Titanic Liverpool",
        id: 68,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    69: {
        img: "/imgs/AllinghamArms.svg",
        name: "Allingham Arms Hotel",
        id: 69,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    74: {
        img: "/imgs/BallyseedeCastle.svg",
        name: "Ballyseede Castle",
        id: 74,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    75: {
        img: "/imgs/HamletCourtHotel.svg",
        name: "Hamlet Court Hotel",
        id: 75,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    77: {
        img: "/imgs/GarryvoeHotel.svg",
        name: "Garryvoe Hotel",
        id: 77,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    78: {
        img: "/imgs/TheLansdowneHotel.svg",
        name: "The Lansdowne Hotel",
        id: 78,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    79: {
        img: "/imgs/BeechHillCountryHouse.svg",
        name: "Beech Hill Country House",
        id: 79,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    80: {
        img: "/imgs/CabraCastle.svg",
        name: "Cabra Castle Hotel",
        id: 80,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
        hide: {
            Vouchers: 1,
        },
    },
    84: {
        img: "/imgs/ArmaghCityHotel.svg",
        name: "Armagh City",
        id: 84,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    85: {
        img: "/imgs/WellingtonParkHotel.svg",
        name: "Wellington Park Hotel",
        id: 85,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    86: {
        img: "/imgs/KillarneyRoyal.svg",
        name: "Killarney Royal",
        id: 86,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    88: {
        img: "/imgs/ParkHouse.png",
        name: "Park House Hotel",
        id: 88,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    89: {
        img: "/imgs/GreatSouthern.svg",
        name: "Great Southern Hotel Killarney",
        id: 89,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    90: {
        img: "/imgs/Abbeyglen.png",
        name: "Abbeyglen Castle Hotel",
        id: 90,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    91: {
        img: "/imgs/Maryborough.svg",
        name: "Maryborough Hotel",
        id: 91,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    // Imperial London Hotels Group
    92: {
        img: "/imgs/ImperialLondon.svg",
        name: "Imperial London Hotels Group",
        id: 92,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        parent: ILH_HOTEL_LIST,
    },
    93: {
        img: "/imgs/ILHTavistock.svg",
        name: "Tavistock Hotel",
        id: 93,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    94: {
        img: "/imgs/ILHPresident.svg",
        name: "President Hotel",
        id: 94,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    95: {
        img: "/imgs/ILHMorton.svg",
        name: "The Morton Hotel",
        id: 95,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    96: {
        img: "/imgs/ILHBedford.svg",
        name: "Bedford Hotel",
        id: 96,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    97: {
        img: "/imgs/ILHRoyalNational.svg",
        name: "Royal National Hotel",
        id: 97,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    107: {
        img: "/imgs/ILHCitySleeper.svg",
        name: "City Sleeper Hotel",
        id: 107,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
            AvailabilityAndDemand: 1,
            AroInsights: 1,
            LostRevenue: 1,
        },
        show: {
            Membership: 1,
        },
        isChild: true,
    },
    // End ILH
    98: {
        img: "/imgs/ChesterGrosvenor.svg",
        name: "The Chester Grosvenor Hotel",
        id: 98,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
        },
    },

    105: {
        img: "/imgs/Ardilaun.svg",
        name: "The Ardilaun Hotel",
        id: 105,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    110: {
        img: "/imgs/Gotham.png",
        name: "Hotel Gotham",
        id: 110,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
        hide: {
            Vouchers: 1,
        },
    },

    112: {
        img: "/imgs/glasshouse.svg",
        name: "Glasshouse Hotel",
        id: 112,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    113: {
        img: "/imgs/pembroke.svg",
        name: "Pembroke Kilkenny",
        id: 113,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },

    // 106: {
    //     img: "/imgs/HopeStreet.svg",
    //     name: "Hope Street",
    //     id: 106,
    //     breakfast: 16,
    //     tax: 0.91,
    // },
    99: {
        img: "/imgs/Glenlo.svg",
        name: "OLD - Glenlo Abbey",
        id: 99,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
        hide: {
            Vouchers: 1,
        },
    },
    111: {
        img: "/imgs/glenlo_lodges.png",
        name: "OLD - Glenlo Abbey Lodges",
        id: 111,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
        hide: {
            Vouchers: 1,
        },
    },
    131: {
        img: "/imgs/PrinceOfWales.svg",
        name: "Prince of Wales Hotel",
        id: 131,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    115: {
        img: "/imgs/CashelPalace.svg",
        name: "Cashel Palace",
        id: 115,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    114: {
        img: "/imgs/BrochnerHotels.svg",
        name: "Brøchner Hotels",
        simplified_name: "Brochner Hotels", // This is to allow search by "Bro" with not an "ø" letter
        id: 114,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        parent: BROCHNER_HOTEL_LIST,
    },
    116: {
        img: "/imgs/DanmarkBrochnerHotels.svg",
        name: "Hotel Danmark",
        id: 116,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        hide: {
            Vouchers: 1,
        },
        isChild: true,
    },
    117: {
        img: "/imgs/OttiliaBrochnerHotels.svg",
        name: "Hotel Ottilia",
        id: 117,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        hide: {
            Vouchers: 1,
        },
        isChild: true,
    },
    118: {
        img: "/imgs/SP34BrochnerHotels.svg",
        name: "Hotel SP34",
        id: 118,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        hide: {
            Vouchers: 1,
        },
        isChild: true,
    },
    119: {
        img: "/imgs/SP34ApartmentsBrochnerHotels.svg",
        name: "SP34 Apartments",
        id: 119,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        hide: {
            Vouchers: 1,
        },
        isChild: true,
    },

    120: {
        img: "/imgs/AnandaInTheHimalayas.png",
        name: "Ananda In The Himalayas",
        id: 120,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
    },
    121: {
        img: "/imgs/GoldenRockInn-black.png",
        name: "Golden Rock Inn",
        id: 121,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
    },
    122: {
        img: "/imgs/TheCliffHotel.png",
        name: "The Cliff Hotel",
        id: 122,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
    },
    123: {
        img: "/imgs/WaterfordCastleResort.svg",
        name: "Waterford Castle Resort Lodges",
        id: 123,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    124: {
        img: "/imgs/SaltmarsheHall.svg",
        name: "Saltmarshe Hall",
        id: 124,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    125: {
        img: "/imgs/ParkKenmare.svg",
        name: "Park Hotel Kenmare",
        id: 125,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    127: {
        img: "/imgs/LoughEske.svg",
        name: "Lough Eske",
        id: 127,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    128: {
        img: "/imgs/OxPastureHall.png",
        name: "Ox Pasture Hall",
        id: 128,
        breakfast: 16,
        tax: 0.91,
        region: "en-UK",
    },
    130: {
        img: "/imgs/Ycona.svg",
        name: "Ycona Luxury Resort",
        id: 130,
        breakfast: 16,
        tax: 0.91,
        region: "sw-TZ",
    },
    132: {
        img: "/imgs/Roundstone.svg",
        name: "Roundstone Selfcatering",
        id: 132,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    133: {
        img: "/imgs/FernhillHouseHotel.svg",
        name: "Fernhill House Hotel",
        id: 133,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    134: {
        img: "/imgs/Wildlands.webp",
        name: "Wildlands",
        id: 134,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    135: {
        img: "/imgs/MarkreeCastle.svg",
        name: "Markree Castle",
        id: 135,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    136: {
        img: "/imgs/OneTheWaterFront.svg",
        name: "One the Waterfront",
        id: 136,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    137: {
        img: "/imgs/McGrory.svg",
        name: "McGrorys",
        id: 137,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    139: {
        img: "/imgs/HotelHans.svg",
        name: "Hotel Hans",
        id: 139,
        breakfast: 16,
        tax: 0.91,
        region: "da-DK",
        // hide: {
        //     Vouchers: 1,
        // },
        isChild: true,
    },
    140: {
        img: "/imgs/CartonHouse.svg",
        name: "Carton House",
        id: 140,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
    141: {
        img: "/imgs/Ballyfin.svg",
        name: "Ballyfin Demesne",
        id: 141,
        breakfast: 16,
        tax: 0.91,
        region: "en-IE",
    },
};

export const YEARS_BEFORE = 5;
export const AI_CANCELLATIONS_THRESHOLD = 0.7;
